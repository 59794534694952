import { Form, Button } from "react-bootstrap"
import { useState } from "react"
import api from "../lib/api.js"

// bootstrap
import Alert from "react-bootstrap/Alert"

export default function ForgotPassword() {
  const [resetAttempted, setResetAttempted] = useState(false)
  const [attemptedEmail, setAttemptedEmail] = useState("")
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  function handleSubmit(event) {
    event.preventDefault()

    // prevent api request if no email or password are given
    const email = event.target[0].value
    if (email.length === 0) return null

    const form_data = { email }

    // Disable the button immediately
    setIsButtonDisabled(true)

    api.post("/resetpassword", form_data)
    .then(() => {
      setResetAttempted(true)
      setAttemptedEmail(email)
    })
    .catch((error) => {
      setResetAttempted(true)
      setAttemptedEmail(email)
      console.error(error.response?.data || error.message)
    })
    .finally(() => {
      // Re-enable the button after 10 seconds
      setTimeout(() => setIsButtonDisabled(false), 5000)
    })
  }

  return (
    <div className="ForgotPassword">
      <Form onSubmit={handleSubmit}>
        <h2>Reset your password</h2>
        <p>Enter the email address associated with your account to reset your password.</p>
        <Form.Group size="lg" controlId="email">
          <Form.Control 
            type="email" 
            placeholder="email"
          />
        </Form.Group>
        {(resetAttempted) && (<Alert variant='success'>If an account exists with your email address, <b>{attemptedEmail}</b>, we just sent you an email with instructions to reset your password.</Alert>)}
        <Button block size="lg" type="submit" disabled={isButtonDisabled}>
          {resetAttempted ? "Resend email" : "Send email"}
        </Button>
      </Form>
    </div>
  )
}
