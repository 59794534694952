// react router
import { Route, Routes, Navigate, useLocation, useParams } from "react-router-dom"
import { useEffect, useState, useContext } from 'react'

// context
import { authContext } from './authContext.js'

// components
import Navigation from './Navigation.js'
import ProductTour from './ProductTour.js'
// import Home from './Home.js'
// import GetStarted from './GetStarted.js'
// import Articles from './Articles.js'
// import Article from './Article.js'
import PageNotFound from './PageNotFound.js'
import Login from './Login.js'
import Signup from './Signup.js'
import ForgotPassword from './ForgotPassword.js'
import ResetPassword from "./ResetPassword.js"
import Landing from './app/Landing.js'
import FreeReport from './app/FreeReport.js'
import Settings from './app/Settings.js'
import ManageData from './app/ManageData.js'
import ReportCard from './app/ReportCard.js'
import Calculator from './app/Calculator.js'
import Energy from './app/Energy.js'
import Budget from './app/Budget.js'
import Chat from './app/Chat.js'
import Dashboard from './app/Dashboard.js'
import Admin from './app/Admin.js'

// this is the same as official example: check is user is authorized, then navigates accordingly
function RequireAuth({ children }) {
   const auth = useContext(authContext)
   const location = useLocation()

   // return children if authed is true
   // return Navigate<> element if authed is false
   return auth.authed === true ? children : <Navigate to="/login" replace state={{ path: location.pathname }} />
}

export default function App() {
   const location = useLocation()
   const [currentPage, setCurrentPage] = useState(null)

   useEffect(() => {
      if (location.pathname === "/app/report-card") setCurrentPage("cashflow")
      else if (location.pathname === "/app/energy") setCurrentPage("energy")
      else if (location.pathname === "/app/dashboard") setCurrentPage("investments")
   }, [location])

   // redirects to actual reset password page, passing in the token as state so it is not seen in the url
   function ResetRedirect() {
      const params = useParams()
      const token = params.token
      const searchParams = new URLSearchParams(window.location.search)
      const email = searchParams.get("email")
      return <Navigate to="/reset-password" state={{ token: token, email: email }} />
   }

   return (
      <div id="app">
         <Navigation />
         <ProductTour pathname={location.pathname} />
         <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/" element={<Navigate to="/login" />} />
            {/* <Route path="/getstarted" element={<GetStarted />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/articles/:article_key" element={<Article />}/> */}
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} /> 
            <Route path="/forgot-password" element={<ForgotPassword />} /> 
            <Route path="/reset-password/:token" element={<ResetRedirect />}/>
            <Route path="/reset-password" element={<ResetPassword />}/>
            <Route path="/app"
               element={
                  <RequireAuth>
                     <Landing />
                  </RequireAuth>
               }
            />
            <Route path="/app/hello"
               element={
                  <RequireAuth>
                     <FreeReport />
                  </RequireAuth>
               }
            />
            <Route path="/app/settings"
               element={
                  <RequireAuth>
                     <Settings />
                  </RequireAuth>
               }
            />
            <Route path="/app/manage-data"
               element={
                  <RequireAuth>
                     <ManageData pageSource={currentPage} />
                  </RequireAuth>
               }
            />
            
            <Route path="/app/report-card"
               element={
                  <RequireAuth>
                     <ReportCard />
                  </RequireAuth>
               }
            />
            <Route path="/app/calculator"
               element={
                  <RequireAuth>
                     <Calculator />
                  </RequireAuth>
               }
            />
            <Route path="/app/dashboard"
               element={
                  <RequireAuth>
                     <Dashboard />
                  </RequireAuth>
               }
            />
            <Route path="/app/energy"
               element={
                  <RequireAuth>
                     <Energy />
                  </RequireAuth>
               }
            />
            <Route path="/app/budget"
               element={
                  <RequireAuth>
                     <Budget />
                  </RequireAuth>
               }
            />
            <Route path="/app/chat"
               element={
                  <RequireAuth>
                     <Chat />
                  </RequireAuth>
               }
            />
            <Route path="/app/admin"
               element={
                  <RequireAuth>
                     <Admin />
                  </RequireAuth>
               }
            />
         </Routes>
      </div>
   )
}
