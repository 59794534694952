import { useNavigate } from "react-router-dom"
import { Button } from "react-bootstrap"
import logo from "../svg/logo.svg"

export default function MissingData(props) {
  const navigate = useNavigate()
  const handleRedirect = () => navigate("/app/manage-data", {state: {pageSource: props.pageSource} })

  const fontScale = props.fontScale || 1
  const buttonScale = props.buttonScale || 1
  
  var showUpload = true
  var showAdditionalText = false
  var showLogo = true

  if (props.showUpload == false) showUpload = false
  if (props.additionalText) showAdditionalText = true
  if (props.showLogo == false) showLogo = false
  
  return (
    <div className="missing-data flex-center">
      <div>
        <h1 style={{fontSize: `${2.5 * fontScale}rem`}}>{props.text}</h1>
        <div className="justify-content-center">
          {showAdditionalText && <h5 style={{fontSize: `${1.5 * fontScale}rem`}}>{props.additionalText}</h5>}
          {showUpload && <Button style={{fontSize:`${1.05 * buttonScale}rem`}} onClick={handleRedirect}>Upload</Button>}
        </div>
        {showLogo && <img alt="Vertical City Logo" src={logo} style={{maxHeight: 500, maxWidth: 500}} />}
      </div>
    </div>           
  )
}