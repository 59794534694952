import { useEffect, useState, useContext } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { OverlayTrigger, Popover, PopoverBody, Alert } from "react-bootstrap"

// context
import { authContext } from '../authContext.js'
import { dataContext } from '../dataContext.js'

// Components
import MissingData from '../MissingData.js'

// Helper Functions
import { onMouseOverSync, onMouseOutSync, dateConversion, helperPlot, helperPlotRange, chartExtremes } from "../../lib/helpers/highchartsHelpers.js"
import { formatAmount, formatAmountKM, formatDate, capitalize, helperDateDifference } from '../../lib/helpers/formattingHelpers.js'

// svgs
import logoBlue from '../../svg/mini-logo-blue.svg'
import logoGreen from '../../svg/mini-logo-green.svg'
import logoYellow from '../../svg/mini-logo-yellow.svg'
import check from '../../svg/check.svg'
import x from '../../svg/x-red.svg'

// tooltip flag interface
import { getEnergyFlag } from '../../lib/helpers/tooltipFlags'

function helperCostPerUnit(date, cost, consumption) {
  var output = []
  for (let i = 0; i < date.length; ++i)
  {
    // convert to date
    var dateObj = dateConversion(date[i])
    // divide
    var quotient = cost[i] / consumption[i]
    // make array of pairs
    var element = [dateObj.getTime(), quotient]
    output.push(element)
  }
  return output
}

function helperRegressionDifference(projectedConsumption, actualConsumption) {
  let output = []
  for (let i in actualConsumption)
  {
    output.push([actualConsumption[i][0], actualConsumption[i][1] - projectedConsumption[i][1]])
  }
  return output
}

function helperPlotRollingAverage(date, cost, consumption, monthWindow = 12) {
  const output = []
  let quotient = []

  for (let i in cost)
  {
    quotient.push(cost[i] / consumption[i])
  }

  for (let i in date)
  {
    // convert to date
    var dateObj = dateConversion(date[i])
    if (i < 12)
    {
      // if month < 12, return undefined point (1/0) so that the first 12 months do not show on graph (while keeping the charts properly synced)
      output.push([dateObj.getTime(), 1 / 0])
    }
    else
    {
      // creates a 12 month array out of quotient array (if less than 12, goes from starting date until present month)
      const monthWindowSlice = quotient.slice(i - monthWindow, i)
      // adds up all numbers in said array
      const sum = monthWindowSlice.reduce((prev, curr) => prev + curr, 0)
      // find average and push
      output.push([dateObj.getTime(), sum / monthWindow])
    }
  }

  return output
}

export default function Energy() {
  // context
  const auth = useContext(authContext)
  const data = useContext(dataContext)

  // show currently selected energy type
  const [showCurrent, setShowCurrent] = useState("")

  // state of single array per line per charts
  const [dataProjected, setDataProjected] = useState([])
  const [dataProjectedRange, setDataProjectedRange] = useState([])
  const [dataActual, setDataActual] = useState([])
  const [dataCostPerUnit, setDataCostPerUnit] = useState([])
  const [dataCostRollingAverage, setDataCostRollingAverage] = useState([])

  const [regressionDailyActual, setRegressionDailyActual] = useState([])
  const [regressionDailyProjected, setRegressionDailyProjected] = useState([])
  const [regressionDailyProjectedRange, setRegressionDailyProjectedRange] = useState([])
  const [regressionDifference, setRegressionDifference] = useState([])

  // chart options
  const [consumptionChartOptions, setConsumptionChartOptions] = useState({})
  const [regressionChartOptions, setRegressionChartOptions] = useState({})
  const [regressionDiffChartOptions, setRegressionDiffChartOptions] = useState({})
  const [costChartOptions, setCostChartOptions] = useState({})

  // for mobile screens
  const [mobileScreen, setMobileScreen] = useState(true)
  const [costChartHeight, setCostChartHeight] = useState('15%')
  const [chartSpacing, setChartSpacing] = useState([20,20,20,20])

  // mouse over point
  const [mouseOver, setMouseOver] = useState(true)
  const [mouseOverPoint, setMouseOverPoint] = useState({})
  
  // updates chart properties based on window size
  const resizeMobile = () => {
    if (window.matchMedia("(max-width: 560px)").matches) {
      setCostChartHeight('40%')
      setChartSpacing([10,0,0,0])
      setMobileScreen(true)
    } else if(!window.matchMedia("(max-width: 560px)").matches ) {

      if(window.matchMedia("(max-width: 1200px)").matches){
        setCostChartHeight('26%')
      } else {
        setCostChartHeight('15%')
      }

      setChartSpacing([20,20,20,20])
      setMobileScreen(false)
    }
  }

  //------------------------------------------------------------------------------------------------------------------
  // Component Hooks
  //------------------------------------------------------------------------------------------------------------------

  // fetch which utility data types are available
  useEffect(() => {
    if (auth.isContextSecured)
    {
      data.fetchUtilities()
    }
  }, [auth.isContextSecured])

  // hook fetch all available utility data types
  useEffect(() => {
    if (auth.isContextSecured && data.availableUtilities.length > 0)
    {
      data.fetchAllUtilityData()
    }
  }, [auth.isContextSecured, data.availableUtilities])

  // hook updating main chart
  useEffect(() => {
    if (Object.keys(data.utilityData).length !== 0 && data.selectedUtility)
    {
      const dataUtility = data.utilityData[data.selectedUtility]
      if (!dataUtility?.startdate) return
      
      setShowCurrent(capitalize(data.selectedUtility))
      setDataProjectedRange(helperPlotRange(dataUtility['startdate'], dataUtility['projconsumptionlow'], dataUtility['projconsumptionhigh'], true))
      setDataProjected(helperPlot(dataUtility['startdate'], dataUtility['projconsumption'], true))
      setDataActual(helperPlot(dataUtility['startdate'], dataUtility['consumption'], true))
      setDataCostPerUnit(helperCostPerUnit(dataUtility['startdate'], dataUtility['cost'], dataUtility['consumption']))
      setDataCostRollingAverage(helperPlotRollingAverage(dataUtility['startdate'], dataUtility['cost'], dataUtility['consumption']))

      let tempRegressionDailyProjected = helperPlot(dataUtility['temperature'], dataUtility['dailyprojavg'], false, true)
      let tempRegressionDailyActual = helperPlot(dataUtility['temperature'], dataUtility['dailyconsumption'], false, true)
      // let tempRegressionDailyProjected = helperPlot(data['temperature'], data['projconsumption'], false, true)
      // let tempRegressionDailyActual = helperPlot(data['temperature'], data['consumption'], false, true)

      setRegressionDailyProjected(tempRegressionDailyProjected)
      setRegressionDailyProjectedRange(helperPlotRange(dataUtility['temperature'], dataUtility['dailyprojlow'], dataUtility['dailyprojhigh'], false, true))
      // setRegressionDailyProjectedRange(helperPlotRange(data['temperature'], data['projconsumptionlow'], data['projconsumptionhigh'], false, true))
      setRegressionDailyActual(tempRegressionDailyActual)
      setRegressionDifference(helperRegressionDifference(tempRegressionDailyProjected, tempRegressionDailyActual))
    }
  }, [data.selectedUtility, data.utilityData])

  // Mouse Out Chart Component Hook
  useEffect(() => {
    if (mouseOver == false)
    {
      Highcharts.charts.forEach((chart) => {
        if (chart && (chart.title.textStr === "consumption"))
        {
          var lastIndex = chart.series[0].points.length - 1
          var point = chart.series[0].points[lastIndex]
          setMouseOverPoint(point)
        }
      })
    }
  }, [mouseOver])
  
  // -------------------------------------------------------------------------------
  // Components
  // -------------------------------------------------------------------------------

  function TotalsHeader() {
    if (Object.keys(mouseOverPoint).length === 0 ) return <></>
    if (mouseOverPoint.series === null ) return <></>
    
    var chart = mouseOverPoint.series.chart
    var index = mouseOverPoint.index
    var date = new Date(mouseOverPoint.x)
    var billDate = formatDate(date, false)

    var actual = null
    var predicted = null
    var predictedRange = null

    // sets label data
    chart.series.forEach(s => {
      if (s.name === "Actual Consumption")
      {
        actual = s.yData[index]
      }
      else if (s.name === "Predicted Consumption")
      {
        predicted = s.yData[index]
      }
      else if (s.name === "Predicted Consumption Range")
      {
        predictedRange = s.yData[index]
      }
    })

    function ActualConsumption() {
      return (
        <div className='component-container totals-header'>
            <div className='card-container'>
              <div style={{'width':'90%'}}>
                <h5 style={{color:'#f1c31a'}}>Actual Consumption</h5>
                <p id='portfolio-date' style={{'marginTop':'0px'}}>{billDate}</p>
              </div>
              <div className='vertical'>
                <div  className='flex'>
                  <div className='legend-size circle-legend' id='study-bm' />
                  <div className='amount big'>{formatAmount(actual, 0, false)}</div>
                </div>
                <div style={{'textAlign': 'top', 'marginTop': '-10px'}}>
                  <p id='range' className='right smaller' style={{'marginTop': '-20px'}}>--</p>
                </div>
              </div>
            </div>
        </div>
      )
    }

    function PredictedConsumption() {
      return (
        <div className='component-container totals-header' style={{paddingBottom:'0px'}}>
          <div className='card-container'>
            <div style={{'width':'90%'}}>
              <h5 style={{color: '#5d98d1'}}>Predicted Consumption</h5>
              <p id='portfolio-date' style={{'marginTop': '0px'}}>{billDate}</p>
            </div>
            <div className='vertical'>
              <div  className='flex'>
                <div className='legend-size circle-legend' id='true-bm' />
                <div className='amount big'>{formatAmount(predicted, 0, false)}</div>
              </div>
              <div style={{'textAlign': 'top', 'display': 'flex', 'marginTop': '-20px'}}>
                <div style={{'flex': '1', 'flexShrink': 0, 'marginTop': '40.25px', 'paddingLeft': '6px'}}>
                  <p id='range' className='right smaller' style={{'whiteSpace': 'nowrap', 'marginTop': '-34px'}}>{formatAmountKM(predictedRange[0], false)+ " to " + formatAmountKM(predictedRange[1], false)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className='flex-column-mobile totals' style={{'marginBottom': '0px'}}>
        <ActualConsumption />
        <PredictedConsumption />
      </div>
    )
  }

  // energy summary component
  function OutOfRangeInfo(props) {

    const range = props.data["range"]
    var sumDifference = 0
    var sumAmounts = 0

    for(let i = 0; i < range.length; i++)
    {
      sumDifference += range[i]["difference"]
      sumAmounts += range[i]["amount"]
    }

    var prevDate = new Date(formatDate(range[range.length - 1]["date"]))
    prevDate.setMonth(prevDate.getMonth()+1)

    if (props.data["type"] === "+") 
    {
      var percentage = (range[0]["difference"]*100)/range[0]["amount"]
      var amt = (70*percentage)/100 

      // single date case
      if (range.length === 1)
      {

        return (
          <OverlayTrigger  placement="top" overlay={
            <Popover className="popover-data">
              <PopoverBody>
                  <div id='consumption-text'>
                  Consumption was {formatAmount(range[0]["difference"], 2, false)} above our projections.
                  </div>
                  <div id='consumption-amount'>
                    <b >{formatAmount(percentage, 0, false)}% </b>
                  </div>
                  <div className='consumption-wrapper'>
                    <div id ="difference-text" ><b>Difference: </b></div>
                    <div className='consumption-container'>
                      <div id='consumption-bar-left' style={{backgroundImage: 'none'}}></div>
                    </div>
                    <div className='consumption-container'>
                      <div id='consumption-bar-right' style={{width:`${amt}px`}}> </div>
                    </div>
                  </div>
              </PopoverBody>
            </Popover>
            }>
            <Alert className='alert-detail' variant='danger'>
              <p>
               <img src={x} alt="X Emoji" width="20" height="20" />
            {formatDate(range[0]["date"])} above expected</p></Alert>

          </OverlayTrigger>
        )
      }

      var percentage = (sumDifference*100)/sumAmounts 
      var amt = (70*percentage)/100

      return (
        <OverlayTrigger placement="top" overlay={
          <Popover className="popover-data">
            <PopoverBody>
              <div id='consumption-text'>
                  Your total difference across {range.length} bills for {props.type} consumption is {formatAmount(sumDifference, 2, false)} above our projections.
              </div>
              <div id='consumption-amount'>
                  <b>{formatAmount(percentage, 0, false)}%</b> 
              </div>
              <div className='consumption-wrapper'>
                <div id ="difference-text"><b>Difference: </b></div>
                <div className='consumption-container'>
                  <div id='consumption-bar-left' style={{backgroundImage: 'none'}} ></div>
                </div>
                <div className='consumption-container'>
                  <div id='consumption-bar-right' style={{width:`${amt}px`}}></div>
                </div>
              </div>
            </PopoverBody>
          </Popover>
          }>
          <Alert className='alert-detail' variant='danger'>
            <p>
              <img src={x} alt="X Emoji" width="20" height="20" />
               {formatDate(range[0]["date"])}-{formatDate(prevDate)} above expected
            </p>
          </Alert>
        </OverlayTrigger>
      )

    }
    else if (props.data["type"] === "-")
    {

      // single date case
      if (range.length === 1)
      {
        var percentage = (range[0]["difference"]*100)/range[0]["amount"] 
        var amt = (70*percentage)/100
        
        return (
          <OverlayTrigger placement="top" overlay={
            <Popover className="popover-data">
              <PopoverBody>
                <div id='consumption-text'>
                  Consumption was {formatAmount(range[0]["difference"], 2, false)} below our projections.
                </div>
                <div id='consumption-amount'>
                  <b>{formatAmount(percentage, 0, false)}% </b>
                </div>
                <div className='consumption-wrapper'>
                  <div id ="difference-text"><b>Difference: </b></div>
                  <div className='consumption-container'>
                    <div id='consumption-bar-left' style={{width:`${amt}px`}} ></div>
                  </div>
                  <div className='consumption-container'>
                    <div id='consumption-bar-right' style={{backgroundImage: 'none'}} > </div>
                  </div>
                </div>
              </PopoverBody>
            </Popover>
            }>
            <Alert className='alert-detail' variant='success'><p><img src={check} alt="Check Emoji" width="20" height="20" />
              {formatDate(range[0]["date"])} below expected</p></Alert>
          </OverlayTrigger>
        )
      }

      var percentage = (sumDifference*100)/sumAmounts
      var amt = (70*percentage)/100

      return (
        <OverlayTrigger placement="top" overlay={
          <Popover className="popover-data">
            <PopoverBody>
              <div id='consumption-text'>
                Your total difference across {range.length} bills for {props.type} consumption is {formatAmount(sumDifference, 2, false)} below our projections.
              </div>
              <div id='consumption-amount'>
                <b>{formatAmount(percentage, 0, false)}% </b> 
              </div>
                <div className='consumption-wrapper'>
                  <div id ="difference-text"><b>Difference: </b></div>
                  <div className='consumption-container'>
                    <div id='consumption-bar-left' style={{width:`${amt}px`}}></div>
                  </div>
                  <div className='consumption-container'>
                    <div id='consumption-bar-right' style={{backgroundImage: 'none'}}> </div>
                  </div>
                </div>
            </PopoverBody>
          </Popover>
          }>
          <Alert className='alert-detail' variant='success'>
            <p>
              <img src={check} alt="Check Emoji" width="20" height="20" />
              {formatDate(range[0]["date"])}-{formatDate(prevDate)} below expected
            </p>
          </Alert>
        </OverlayTrigger>
      )
    }
  }

  // -------------------------------------------------------------------------------
  // Highcharts
  // -------------------------------------------------------------------------------

  function onMouseOverSyncEnergy(point, e, xCharts = ["default"], yCharts = ["default"]) {
    // converts daily consumption to monthly consumption or vice versa to sync charts
    const index = point.index
    const currentChartActualConsumption = point.series.chart.series[2].yData[index]

    let adjustedConsumption

    if (point.series.chart.title.textStr === "consumption")
    {
      const diffDays = helperDateDifference(point.x, dateConversion(data.utilityData[data.selectedUtility]["enddate"][index])) + 1
      adjustedConsumption = currentChartActualConsumption / diffDays
    }
    else if (point.series.chart.title.textStr === "regression")
    {
      const dailyConsumptionIndex = data.utilityData[data.selectedUtility]["dailyconsumption"].indexOf(currentChartActualConsumption)
      const diffDays = helperDateDifference(dateConversion(data.utilityData[data.selectedUtility]["startdate"][dailyConsumptionIndex]), dateConversion(data.utilityData[data.selectedUtility]["enddate"][dailyConsumptionIndex])) + 1
      adjustedConsumption = currentChartActualConsumption * diffDays
    }

    // looping through all charts
    Highcharts.charts.forEach(chart => {
      if (chart)
      {
        // syncing up x axis of desired charts
        if (xCharts.includes(chart.title.textStr) || xCharts.includes("default"))
        {
          let points = []
          // looping through series
          chart.series.forEach(s => {
            // syncs points between the graphs
            const syncedPoint = s.data[point.index]
            // sets point to 'hover' (have a highlighted marker over it)
            syncedPoint.setState('hover')
            points.push(syncedPoint)
            // draws crosshair for point
            syncedPoint.series.chart.xAxis[0].drawCrosshair(e, syncedPoint)
          })
          // syncing tooltip
          chart.tooltip.refresh(points)
        }
        // syncing up y axis of desired charts
        else if (yCharts.includes(chart.title.textStr) || yCharts.includes("default"))
        {
          // looping through series
          chart.series.forEach(s => {
            if (s.name === "Actual Consumption")
            {
              // looping through points
              s.points.forEach(p => {
                // 1% error bound to accomodate for estimation of daily consumption
                if (Math.abs(adjustedConsumption - p.y) < adjustedConsumption * 0.01)
                {
                  const pointIndex = p.index

                  // syncing tooltip - goes through all series in chart to properly refresh tooltip
                  let points = []
                  chart.series.forEach(series => {
                    points.push(series.data[pointIndex])
                  })

                  p.series.chart.tooltip.refresh(points)
                  // draws crosshair for point
                  p.series.chart.yAxis[0].drawCrosshair(e, p)
                }
              })
            }
          })
        }
      }
    })
  }

  // syncs up consumption and cost chart min and max x axis extremes
  function onZoomSync({ min, dataMax }) {
    // goes through all charts, looks for charts with the title consumption (consumption and cost)
    Highcharts.charts.forEach((chart) => {
      if (chart && (chart.title.textStr === "consumption" || chart.title.textStr === "cost"))
      {
        // manually sets max and min x values
        chart.xAxis[0].setExtremes(min, dataMax)
      }
    })
  }

  // -------------------------------------------------------------------------------
  // Charts
  // -------------------------------------------------------------------------------
  
  useEffect(() => {
    if (dataActual.length > 0)
    {
      var extremesConsumption = chartExtremes([dataProjectedRange, dataProjected, dataActual])
      setConsumptionChartOptions(
        {
          chart: {
            type: "spline",
            spacing: chartSpacing,
            events: {
              // update main chart label for some non-mouseover edge cases
              render: function () {
                if (this.fakeLabel)
                {
                  var lastIndex = this.series[0].points.length - 1
                  var point = this.series[0].points[lastIndex]
                  setMouseOverPoint(point)

                  // console.log(this.rangeSelector.selected)
                  // var max = this.xAxis[0].dataMax
                  // var min
                  // if (this.rangeSelector.selected == undefined) return
                  // if (this.rangeSelector.selected == 0) min = max - 31556952000
                  // else if (this.rangeSelector.selected == 1) min = max - (3 * 31556952000)
                  // else if (this.rangeSelector.selected == 2) min = this.xAxis[0].dataMin
                  // console.log(min, max)
                  //onZoomSync({min: min, max: max})
                }
              }
            }
          },
          title: {
            text: "consumption",
            style: {
              display: 'none'
            }
          },
          legend: {
            enabled: !mobileScreen
          },
          tooltip: {
            split: false,
            crosshairs: true,
            shared: true,
            valueDecimals: 0,
            formatter: function () {
              //console.log(this.points[0].x) // TODO add datetime int to getEnergyFlag map 
              var flag = getEnergyFlag(auth.selectedBuilding, this.points[0].x)
              if (!flag || mobileScreen) return false
              else
              {
                return `<div><b>${flag}</b></div>`
              }
            }
            // formatter: function (tooltip) {
            //   const endDate = utilityData[selectedUtility]["enddate"]
            //   const dateObj = dateConversion(endDate[this.points[0].point.index])
  
            //   const header = 'Bill: <b>' + Highcharts.time.dateFormat('%b %d, %Y', this.x) + ' to ' + Highcharts.time.dateFormat('%b %d, %Y', dateObj) + '</b><br/>'
  
            //   // tooltip.bodyFormatter outputs the default body of the tooltip (legend, series name, value)
            //   return header + (tooltip.bodyFormatter(this.points).join(''));
            // }
          },
          // controls the zoom of the graph
          rangeSelector: {
            enabled: true,
            allButtonsEnabled: true,
            buttons: [
              {
                type: 'year',
                count: 1,
                text: '1 Year'
              },
              {
                type: 'year',
                count: 3,
                text: '3 Years'
              },
              {
                type: 'all',
                text: 'All'
              }
            ],
            buttonTheme: {
              width: 100,
              height: 30
            },
            selected: 0
          },
          // disables draggable window to change zoom/range
          navigator: {
            enabled: false
          },
          scrollbar: {
            enabled: false
          },
          yAxis: {
            title: { text: " " },
            opposite: false,
            showLastLabel: true,
            crosshair: true,
            max: extremesConsumption.max,
            min: extremesConsumption.min,
            tickPositioner: function () {
              if (this.tickPositions)
              {
                return [extremesConsumption.min, extremesConsumption.max]
              }
            },
            labels: {
              formatter: function () {
                return formatAmountKM(this.value, false)
              }
            }
          },
          xAxis: {
            type: 'datetime',
            crosshair: true,
            // syncs up the zoom between cost chart and consumption chart
            events: {
              afterSetExtremes(e) {
                // fire on all events after first load
                if (this.chart.fakeLabel)
                {
                  if (e.trigger == "pan")
                  {
                    // fire if trigger is pan
                    onZoomSync({min: e.min, dataMax: e.max})
                    return
                  }

                  // fire if trigger is rangeSelectorButton or utility type switched 
                  onZoomSync(e)
                }
                // fire on first load
                else
                {
                  var lastIndex = this.series[0].points.length - 1
                  var point = this.series[0].points[lastIndex]
                  setMouseOverPoint(point)

                  this.chart.fakeLabel = this.chart.renderer.label("", 10, 10, null, null, null, true).attr({zIndex: 4}).add()
                  var data = {min: e.dataMax - 31556952000, dataMax: e.dataMax}
                  onZoomSync(data)
                }
              }
            }
          },
          series: [
            {
              name: 'Predicted Consumption Range',
              type: 'areasplinerange',
              data: dataProjectedRange,
              color: '#5d98d1',
              zIndex: 0,
              lineWidth: 0,
              linkedTo: ':previous',
              fillOpacity: 0.3,
              marker: {
                enabled: false
              }
            },
            {
              name: 'Predicted Consumption',
              data: dataProjected,
              color: '#5d98d1',
              zIndex: 1,
              marker: {
                lineWidth: 2,
                lineColor: Highcharts.getOptions().colors[0],
                enabled: false,
                symbol: 'circle',
                radius: 4
              }
            },
            {
              name: 'Actual Consumption',
              data: dataActual,
              color: '#fbd57e',
              marker: {
                enabled: false,
                symbol: 'circle',
                radius: 4
              }
            }
          ],
          plotOptions: {
            // disable markers for range lines when hovering over chart 
            areasplinerange: {
              marker: {
                states: {
                  hover: {
                    enabled: false
                  }
                }
              },
            },
            // for sharing chart tooltips/crosshairs on hover
            series: {
              point: {
                events: {
                  mouseOver(e) {
                    onMouseOverSyncEnergy(this, e, ["cost"], ["regression"])
                    setMouseOverPoint(this)
                  },
                  mouseOut() {
                    onMouseOutSync(["cost", "regression"])
                  }
                }
              },
              stickyTracking: true
            }
          },
          credits: {
            enabled: false
          }
        }
      )

      var extremesRegression = chartExtremes([regressionDailyProjectedRange, regressionDailyProjected, regressionDailyActual])
      setRegressionChartOptions(
        {
          chart: {
            type: "spline",
            height: '40%',
            spacing: chartSpacing,
            // backgroundColor: "#f5f5f5"
          },
          title: {
            text: "regression",
            style: {
              display: 'none'
            }
          },
          legend: {
            itemStyle: {
              'cursor': 'default'
            },
            enabled: !mobileScreen
          },
          tooltip: {
            crosshairs: true,
            shared: true,
            positioner: function () {
              return { x: 0, y: 0 }
            },
            formatter: function () {
              const htmlTooltip = 
              `<div>\
                <b>${this.points[0].x}</b>\
                <br/>\
                <span style='color:#5d98d1'>●</span>\
                Regression: <b>${formatAmount(this.points[1].y, 2, false)}</b>\
                <br/>\
                <span style='color:#5d98d1B3'>■</span>\
                Range: <b>${formatAmount(this.points[0].point.options.low, 2, false)}</b> - <b>${formatAmount(this.points[0].point.options.high, 2, false)}</b>\
                <br/>\
                <span style='color:#f1c31a'>●</span>\
                Consumption: <b>${formatAmount(this.points[2].y, 2, false)}</b>\
                <br/>\
              </div>`
              return mobileScreen ? false:htmlTooltip
            }
          },
          yAxis: {
            title: { text: " " },
            max: extremesRegression.max,
            min: extremesRegression.min,
            tickPositioner: function () {
              if (this.tickPositions)
              {
                return [extremesRegression.min, extremesRegression.max]
              }
            },
            labels: {
              formatter: function () {
                return formatAmountKM(this.value, false)
              }
            },
            crosshair: true
          },
          xAxis: {
            crosshair: true,
          },
          series: [
            {
              name: 'Regression Range',
              type: 'areasplinerange',
              data: regressionDailyProjectedRange,
              color: '#5d98d1',
              zIndex: 0,
              lineWidth: 0,
              linkedTo: ':previous',
              fillOpacity: 0.3,
              marker: {
                enabled: false,
                states: {
                  hover: {
                    enabled: false
                  }
                }
              }
            },
            {
              name: 'Regression',
              data: regressionDailyProjected,
              color: '#5d98d1',
              zIndex: 1,
              marker: {
                fillColor: 'white',
                lineWidth: 2,
                symbol: 'circle',
                lineColor: Highcharts.getOptions().colors[0],
                enabled: false
              }
            },
            {
              name: 'Actual Consumption',
              data: regressionDailyActual,
              // this makes the spline behave like a scatter plot, since shared tooltip doesnt work between them
              type: 'spline',
              lineWidth: 0,
              color: '#fbd57e',
              marker: {
                enabled: true,
                fillColor: 'white',
                lineColor: '#f1c31a',
                lineWidth: 2,
                symbol: 'circle',
                states: {
                  hover: {
                    enabled: true,
                    lineColor: 'black',
                    fillColor: '#f1c31a'
                  }
                },
                radius: 5
              },
              states: {
                hover: {
                  lineWidthPlus: 0
                }
              }
            }
          ],
          plotOptions: {
            series: {
              point: {
                events: {
                  mouseOver(e) {
                    let point = this
                    onMouseOverSyncEnergy(point, e, ["regression-difference"], ["consumption"])
                  },
                  mouseOut() {
                    onMouseOutSync(["regression-difference", "consumption"])
                  }
                }
              },
              events: {
                // when clicking on legend, does nothing
                legendItemClick: function () {
                  return false
                }
              }
            }
          },
          credits: {
            enabled: false
          }
        }
      )

      var extremesRegressionDiff = chartExtremes([regressionDifference])
      setRegressionDiffChartOptions(
        {
          chart: {
            type: "column",
            height: '40%',
            spacing: chartSpacing,
            // backgroundColor: "#f5f5f5"
          },
          title: {
            text: "regression-difference",
            style: {
              display: 'none'
            }
          },
          legend: {
            itemStyle: {
              'cursor': 'default'
            },
            enabled: !mobileScreen
          },
          tooltip: {
            crosshairs: true,
            shared: true,
            formatter: function () {
              const htmlTooltip = 
              `<div>\
                <b>${this.points[0].x}</b>\
                <br/>\
                <span style='color:#5d98d1'>●</span>\
                Regression Diff: <b>${formatAmount(this.points[0].y, 2, false)}</b>\
                <br/>\
              </div>`
              return mobileScreen ? false:htmlTooltip
            }
          },
          yAxis: {
            title: { text: " " },
            max: extremesRegressionDiff.max,
            min: extremesRegressionDiff.min,
            tickPositioner: function () {
              if (this.tickPositions)
              {
                return [extremesRegressionDiff.min, 0, extremesRegressionDiff.max]
              }
            },
            labels: {
              formatter: function () {
                return formatAmountKM(this.value, false)
              }
            }
          },
          xAxis: {
            crosshair: true
          },
          series: [
            {
              name: 'Regression Difference',
              type: 'column',
              data: regressionDifference,
              color: '#5d98d1',
              negativeColor: '#fbd57e',
              pointWidth: 3,
              marker: {
                enabled: false
              }
            }
          ],
          plotOptions: {
            series: {
              point: {
                events: {
                  mouseOver(e) {
                    let point = this
                    onMouseOverSync(point, e, ["regression"])
                  },
                  mouseOut() {
                    onMouseOutSync(["regression"])
                  }
                }
              },
              events: {
                legendItemClick: function () {
                  return false
                }
              }
            }
          },
          credits: {
            enabled: false
          }
        }
      )

      var extremesCost = chartExtremes([dataCostPerUnit, dataCostRollingAverage])
      setCostChartOptions(
        {
          chart: {
            type: "spline",
            height: costChartHeight,
            spacing: chartSpacing,
            // events: {
            //   load: function () {
            //     console.log('load cost chart 2')
            //     // manually sets range to 1 year on load (number is year in milliseconds)
            //     const max = this.xAxis[0].max
            //     console.log(max)
            //     //this.xAxis.setExtremes
            //     onZoomSync({ min: max - 31556952000, max: max })
            //   }
            // }
          },
          title: {
            text: "cost",
            style: {
              display: 'none'
            }
          },
          legend: {
            enabled: !mobileScreen
          },
          yAxis: {
            // title: { text: "Canadian Dollars" },
            showLastLabel: true,
            opposite: false,
            max: extremesCost.max,
            min: extremesCost.min,
            tickPositioner: function () {
              if (this.tickPositions)
              {
                return [extremesCost.min, extremesCost.max]
              }
            },
            // tickAmount: 2,
            title: {
              enabled: false
            },
            labels: {
              formatter: function () {
                return formatAmountKM(this.value)
              }
            }
          },
          xAxis: {
            type: 'datetime',
            crosshair: true,
            events: {
              afterSetExtremes(e) {
                // check if first load
                if (this.chart.fakeLabel == undefined)
                {
                  this.chart.fakeLabel = this.chart.renderer.label("", 10, 10, null, null, null, true).attr({zIndex: 4}).add()
                  var data = {min: e.dataMax - 31556952000, dataMax: e.dataMax}
                  onZoomSync(data)
                }
              }
            }
          },
          tooltip: {
            valuePrefix: '$',
            valueDecimals: 2,
            split: false,
            crosshairs: true,
            shared: true,
            formatter: function (tooltip) {
              const endDate = data.utilityData[data.selectedUtility]["enddate"]
              const dateObj = dateConversion(endDate[this.points[0].point.index])
  
              const header = 'Bill: <b>' + Highcharts.time.dateFormat('%b %d, %Y', this.x) + ' to ' + Highcharts.time.dateFormat('%b %d, %Y', dateObj) + '</b><br/>'
  
              // tooltip.bodyFormatter outputs the default body of the tooltip (legend, series name, value)
              return mobileScreen ? false:(header + (tooltip.bodyFormatter(this.points).join('')))
            }
          },
          series: [
            {
              name: `Cost Per Unit of ${showCurrent}`,
              data: dataCostPerUnit,
              color: '#5d98d1'
            },
            {
              name: `Rolling Yearly Cost Average`,
              data: dataCostRollingAverage,
              color: '#fbd57e'
            }
          ],
          rangeSelector: {
            enabled: false
          },
          navigator: {
            enabled: false
          },
          scrollbar: {
            enabled: false
          },
          plotOptions: {
            series: {
              point: {
                events: {
                  mouseOver(e) {
                    let point = this
                    onMouseOverSync(point, e, ["consumption"])
                  },
                  mouseOut() {
                    onMouseOutSync(["consumption"])
                  }
                }
              },
              events: {
                legendItemClick: function () {
                  return false
                }
              },
              // prevents points from having marker
              marker: {
                enabled: false
              }
            }
          },
          credits: {
            enabled: false
          }
        }
      )
    }
  },[dataActual,mobileScreen])

  //Set up risize event listener for chart mobile views
  useEffect(() => {

    //Initialize state variables
    resizeMobile()

    // Add event listener for window resize
    window.addEventListener('resize', resizeMobile)

  }, [])

  // -------------------------------------------------------------------------------
  // old chart components
  // -------------------------------------------------------------------------------

  // TODO in the middle of updating to useEffect() method for setting chart options (chart event.load doesnt fire though so need to keep it this way for now until we have work around)
  // - we need load event to range set extremes of x axis on page load and sync consumption and cost charts

  return (
    <div>
      {data.energyDataState==1 && <MissingData pageSource="energy" text="There is no Utility Data linked to your account." />}
      {data.energyDataState==2 && (
        <div className='content'>
          <div className='energy'>
            <div className='intro'>
              <h1 className='title'>Does your Energy Consumption look normal?</h1>
              <p className='information'>
                Track your building's energy efficiency by comparing actual utility bills against weather-adjusted consumption forecasts. Any significant deviations may indicate underlying issues. <a target="_blank" href="https://www.verticalcityinstitute.com/post/unlocking-the-power-of-energy-analytics-leveraging-data-for-a-sustainable-future">How this works »</a>
              </p>
            </div>

            <div className='header' style={{'paddingTop': '0px'}}>
              <div className='left'>
                <img src={logoBlue} alt="Blue Logo" width="60" height="60" />
                <p className='section-header'>Summary</p>
              </div>
              <div className='divider divider-1'></div>
            </div>
            
            <div className='component'>
              <div className='summary'>
                {data.utilitySummaryData && Object.keys(data.utilitySummaryData).map((utilityType) => (
                  <button id={(utilityType==data.selectedUtility) ? 'selected': ''} className='alert-info component-container' style={{'display': 'flex', 'flexDirection': 'column'}} onClick={() => data.setSelectedUtility(utilityType)} disabled={utilityType==data.selectedUtility}>
                    <div className='utility-summary-header'>
                      <p id='utility-title'>{capitalize(utilityType)}</p>
                      {data.mapUtilityIcons[utilityType] && <img id={utilityType} src={data.mapUtilityIcons[utilityType]["img"]} alt={utilityType} height={data.mapUtilityIcons[utilityType]["height"]} width={data.mapUtilityIcons[utilityType]["width"]} style={{"marginBottom": data.mapUtilityIcons[utilityType]["marginBottom"]}}/>}
                    </div>
                    {data.utilitySummaryData[utilityType].length > 0 && data.utilitySummaryData[utilityType].map((summaryData) => (
                      !mobileScreen && <OutOfRangeInfo data={summaryData} type={utilityType} />
                    ))}
                    {data.utilitySummaryData[utilityType].length === 0 && (
                      !mobileScreen && <Alert className='alert-detail' variant='secondary'><p className='summary-info'>Consumption within expected range</p></Alert>
                    )}
                  </button>
                ))}
              </div>
            </div>

            <div className='header' style={{'paddingTop': '10px'}}>
              <div className='left'>
                <img src={logoGreen} alt="Green Logo" width="60" height="60" />
                <OverlayTrigger placement="right" overlay={<Popover><PopoverBody>Predicted Consumptions calculated using regression of your buildings historical {showCurrent} bills.</PopoverBody></Popover>}>
                  <p className='section-header'>Consumption - {data.selectedUtility.charAt(0).toUpperCase() + data.selectedUtility.slice(1)}</p>
                </OverlayTrigger>
              </div>
              <div className='divider divider-2'></div>
            </div>

            <div className='component'>
              <div className='section-description'>The  chart below compares your actual energy usage (yellow line) against predicted consumption (blue band) based on temperature data. Significant variations between these lines may warrant investigation. The second chart below tracks your energy cost per unit and displays a 12-month rolling average for trend analysis.</div>
              <TotalsHeader />
              <div className='component-container' style={{'padding':'10px'}} onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                {/* {dataActual && <ConsumptionComparisonChart />} */}
                <HighchartsReact constructorType={"stockChart"} highcharts={Highcharts} options={consumptionChartOptions} />
                <div className='cost-chart'>
                  {/* {dataCostRollingAverage && <CostChart />} */}
                  {dataCostRollingAverage && <HighchartsReact constructorType={"stockChart"} highcharts={Highcharts} options={costChartOptions} />}
                </div>
              </div>
            </div>

            <div className='header' style={{'paddingTop': '10px'}}>
              <div className='left'>
                <img src={logoYellow} alt="Yellow Logo" width="60" height="60" />
                <p className='section-header'>Regression Details - {data.selectedUtility.charAt(0).toUpperCase() + data.selectedUtility.slice(1)}</p>
              </div>
              <div className='divider divider-3'></div>
            </div>

            <div className='section-description'>The regression analysis below shows how your building's energy consumption correlates with outdoor temperature. The blue trend line represents expected consumption at any given temperature, calculated from your historical data. Your actual monthly consumption values appear as yellow points, allowing you to identify unusual patterns or outliers.</div>

            <div className='component space-between detail-charts'>
              <div className='component-container' id='detail-chart'>
                {/* <RegressionDifferenceChart /> */}
                {regressionDifference && <HighchartsReact highcharts={Highcharts} options={regressionDiffChartOptions} />}
              </div>
              <div className='component-container' id='detail-chart'>
                {/* <RegressionChart /> */}
                {regressionDailyActual && <HighchartsReact highcharts={Highcharts} options={regressionChartOptions} />}
              </div>
            </div>

            <div className='disclaimer'>
              Please note that Vertical City Toolkit is intended for informational purposes only and should not be construed as expert advice or conclusively accurate.
              The data used by the application will only be as accurate as the data uploaded by users. Always check with your energy provider to confirm figures.
              Projection model uses data from Weather Canada and can not always guarantee the accuracy of the information. 
              Please Take precaution and confirm data when investigating variances.
            </div>

          </div>
        </div>
      )}
    </div>
  )
}
