import { Form } from 'react-bootstrap'
import { authContext } from '../authContext'
import { useContext, useState, useEffect } from 'react'
import { dataContext } from '../dataContext'

export default function Settings() {

  const auth = useContext(authContext)
  
  //Track and set the state of each option
  const [optionStates, setOptionStates] = useState({});

  /*
  EDIT TO ADD NEW OPTIONS 
  */

  //Labels and actions
  function updateTourStatus(show){
    auth.updateTourStatus(show ? "Landing" : "Ignored")
  }
  const options = {
    "Show guided tour" : updateTourStatus
    
  }
  //Get initial state
  useEffect(()=>{
    setOptionStates({"Show guided tour" : auth.tourStatus !== "Ignored"})
  }, [auth.isContextSecured])
  


  return (
    
    <div className="Settings">
      <div className="landing" id="LandingTour">
        <div className="landing-summary">
          <h1>Settings</h1>
          <div className="setting-widget header">
            {
               Object.entries(options).map(
                ([label, action]) => {

                  return <Form className='setting'>
                    <div className='setting-label'>{label}</div>
                    <Form.Check 
                      checked={optionStates[label]}
                      type="switch"
                      id="blue"
                      onChange={(e)=>{
                        action(e.target.checked) 
                        setOptionStates(prev => ({
                          ...prev,
                          [label]: e.target.checked
                        }))
                      }}
                    />
                  </Form>
                }
              )
            }
          </div>
        </div>
      </div>
    </div>
    )
  }
